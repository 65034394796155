import { Component } from '@angular/core';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from '../users/services/user.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html'
})
export class LandingPageComponent {

  constructor(private _baseService: BaseService,
    private deviceDetector: DeviceDetectorService,
    private userService: UserService,
    private fingerprintService: FingerprintjsProAngularService,
    private toastr: ToastrService) {
    this.epicFunction();
    this.baseUrl = window.location.href;
    this.onIdentifyButtonClick();
  }
  baseUrl;
  affilateid;
  transid;
  campid;
  scampid;
  deviceInfo;
  visitorId = localStorage.getItem('visitorId');
  extendedResult;
  devicetype;
  isDisabled: boolean;
  affliateRedirectUrl;

  ngOnInit() {
    const devicetype = this.deviceInfo.os.toLowerCase()

    // Method for affilate user
    let hashUrl = window.location.hash;
    const newUrl = hashUrl?.split('?')[1];
    sessionStorage.setItem("_affiliateUrl", String(newUrl));
    this.affilateid = this.getQueryParam(newUrl, "affid");
    this.transid = this.getQueryParam(newUrl, "tid");
    this.campid = this.getQueryParam(newUrl, "campid");
    this.scampid = this.getQueryParam(newUrl, "scampid");

    if (devicetype === 'android') {
      this.devicetype = 2
    } else if (devicetype === 'ios') {
      this.devicetype = 1
    } else {
      this.devicetype = 3
    }
  }
  // Method for get params data
  getQueryParam(url, param) {
    var params = new URLSearchParams(url);
    return params.get(param);
  }

  // Method for get device data
  epicFunction() {
    this.deviceInfo = this.deviceDetector.getDeviceInfo();
  }

  async onIdentifyButtonClick() {
   this.visitorId = await this.userService.getVistorId();
  }

  // Method for saveAffiliate data
  saveAffiliateData() {
    this.isDisabled = true;
    const body = {
      affId: this.affilateid,
      tId: this.transid,
      campId: this.campid,
      subCampId: this.scampid,
      device: this.devicetype,
      visitorId: this.visitorId,
      userAgent: this.deviceInfo?.userAgent,
      browser: this.deviceInfo?.browser,
      browserVersion: this.deviceInfo?.browser_version,
      os: this.deviceInfo?.os,
      osVersion: this.deviceInfo?.os_version,
      brand: '',
      model: '',
      url: this.baseUrl
    }

    if (body.visitorId === '' || body.visitorId === null || body.visitorId === undefined) {
      delete body.visitorId;
    }

    this._baseService.post(userApi.saveAffiliate, body).then((res: any) => {
      window.location.href = res;
    }).catch((res: any) => {
      this.toastr.error(res.error.message)
    })
  }
}
