import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit {
  id;
  dashboard = false;
  userProfile: any = [];
  fullName;
  email;
  point;
  panelistId;
  level;
  countryCode;
  userProfileImage;
  profileImage;
  levelPoints;
  private hasCheckedEmail = false;
  userAPISubscription:Subscription;
  constructor(
    private _baseService: BaseService,
    private router: ActivatedRoute,
    private userService: UserService,
    private _route: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!this.hasCheckedEmail) {
      const emailElement = document.getElementById('panelistEmail');
      const existingEmail = localStorage.getItem('uEmail')
      const userEmail = emailElement?.textContent || emailElement?.innerText;

      if (userEmail && existingEmail && userEmail.trim() !== existingEmail.trim()) {
        this._route.navigate(['/login'])  // User is not same
        window.location.reload()  
      }

      this.hasCheckedEmail = true;
    }
    this.id = localStorage.getItem('panelistId');
    this.userService.sendPoints.subscribe((res) => (this.point = res));
    this.userService.sendUserLevel.subscribe((resUserLevel:any)=>{
      this.translateLevel(resUserLevel); 
    })
    this.userService.sendCountryCode.subscribe((resCountryCode:any)=>{
      this.countryCode = resCountryCode?.toLowerCase();
    })
    this.userService.sendUserminPoint.subscribe((resUserMinPoint:any)=>{
      this.levelPoints = resUserMinPoint;
    });

    this.userAPISubscription = this.userService.callUserAPI.subscribe((res)=>{
      if (res) {
        this.fetchUserProfile();
        this.showUserProfileUrl();
      }
    })
    this.userService.sendAvatar.subscribe((res: any) => (this.profileImage = res));
    this.userService.sendProfile.subscribe((res: any) => (this.fullName = `${res.firstName} ${res.lastName}`));
  }

  fetchUserProfile() {
    this._baseService.get(userApi.getUserDetail).then(
      (res: any) => {
        if (res.token) {
          localStorage.setItem('access_token', res.token.access.token);
          localStorage.setItem('refresh_token', res.token.refresh.token);
        }
        this.userService.countryCultureSource.next(localStorage.getItem('Uculture'))
        this.handleUserProfile(res);
        this.showUpdatedUserData();
      }
    ).catch((error: any) => {
      // Handle error
    });
  }

  handleUserProfile(res: any) {
    this.userProfile = res.token ? res.data[0] : res;
    this.fullName = `${this.userProfile.firstName} ${this.userProfile.lastName}`;
    this.email = this.userProfile.email;
    sessionStorage.setItem('iEV', this.userProfile.isEmailVerified);
    localStorage.setItem('uEmail', this.email)
    this.userService.sendUserProfile.next(this.userProfile);
  }

  translateLevel(panelistLevel: string) {
    const lowercaseLevel = panelistLevel?.toLowerCase();
    const removeSpaceLevel = lowercaseLevel?.replace(' ','')
    this.translate.get(`${removeSpaceLevel}`).subscribe((res: any) => {
      this.level = res;
    });
  }


  showUserProfileUrl() {
    this.profileImage = localStorage.getItem('profileImage')
  }

  getTooltipText():string{
    const level = this.translate.instant('levelsInfo');
    const minimumPoints = this.translate.instant('minimumPoints')
    const levelText = this.level ? this.level?.toUpperCase() : '';
    return `${level} ${levelText} ${minimumPoints} ${this.levelPoints} ${this.translate.instant('for_redemption')}`;
  }

  showUpdatedUserData() {
    this.userService.sendUserProfile.subscribe((res: any) => {
      this.fullName = `${res.firstName} ${res.lastName}`;
      this.email = res.email;
    });
    this.userService.sendUserLevel.subscribe((resUserLevel:any)=>{
      this.translateLevel(resUserLevel); 
    })
  }

  ngOnDestroy() {
    this.userAPISubscription.unsubscribe();
  }
}
