<div class="container-fluid">
  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'pointsDetails'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="dashboardhr-line" />
    </div>
  </div>
  <div class="row fixed-body">
    <div class="row dashboard-cards p-0 mb-0">
      <div class="col-md-3">
        <div [ngClass]="!showPoints?'card':'card card-active'" (click)="getpopUp()">
          <h3 class="card-text">{{totalPoint}}</h3>
          <small>{{'totalPoints'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showPoints">
          <div routerLink="/login/body/redeem-point">
            {{'redeemPoints'|translate}}</div>
          <!-- <div>Show Survey History</div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div [ngClass]="!showCompleteSurvey?'card':'card card-active'" (click)="getCompleteSurvey()">
          <h3 class="card-text">{{completeSurvey}}</h3>
          <small>{{'surveysCompleted'|translate}}</small>
        </div>
        <!-- <div class="row showpop" *ngIf="showCompleteSurvey">
          <div>{{'showSurveyHistory'|translate}}</div>
        </div> -->
      </div>
      <div class="col-md-3">
        <div [ngClass]="!showSentReferrals?'card':'card card-active'" (click)="getSentReferrals()">
          <h3 class="card-text">{{sentRecentReferral}}</h3>
          <small>{{'referralsSent'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showSentReferrals">
          <div routerLink="/login/body/refer-earn">
            {{'referEarn'|translate}}
          </div>
          <!-- <div>Show Survey History</div> -->
        </div>
      </div>

      <!-- Tabs -->
      <div class="col-md-3">
        <div [ngClass]="!showAvailablePoint?'card':'card card-active'" (click)="getAvailablePoint()">
          <h3 class="card-text">{{availablePoints}}</h3>
          <small>{{'balancePoints'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showAvailablePoint">
          <div routerLink="/login/body/redeem-point">
            {{'redeemPoints'|translate}}</div>
        </div>
      </div>
    </div>

    <div class="row d-flex point-detail">
      <div class="col-sm-4 mb-4">
        <div class="d-flex flex-direction-column btn-surveypoint btn-group"  (click)="getTabSelection('surveyPoint')">
          <div class="input-group">
            <span [ngClass]="surveyPointer?'active_point input-group-text px-3':'default_point input-group-text px-3'" id="basic-addon1">{{totalSurveyPoint}}</span>
            <a [ngClass]="surveyPointer?'active_pointbtn input-group-text':'default_pointbtn input-group-text'">{{'surveyPoints'|translate}}</a>
          </div>
       </div>
        <!-- <div class="d-flex flex-direction-column" [ngClass]="surveyPointer ? 's-point' : 'default-tab'">
          <span class="" [ngClass]="surveyPointer ? 's-point-span' : 'default-tab-span'">{{totalSurveyPoint}}</span>
          <a class=""
            [ngClass]="surveyPointer ? 's-point-anchor' : 'default-tab-anchor'">{{'surveyPoints'|translate}}</a>
        </div> -->
      </div>
      <div class="col-sm-4 mb-4">
        <div class="d-flex flex-direction-column btn-surveypoint btn-group"  (click)="getTabSelection('referalPoint')">
          <div class="input-group">
            <span [ngClass]="referalPoint?'active_point input-group-text':'default_point input-group-text'" id="basic-addon1">{{totalReferPoint}}</span>
            <a [ngClass]="referalPoint?'active_pointbtn input-group-text':'default_pointbtn input-group-text'">{{'referralPoints'|translate}}</a>
          </div>
       </div>
        <!-- <div class="d-flex flex-direction-column" [ngClass]="referalPoint ? 's-point' : 'default-tab'"
         >
          <span [ngClass]="referalPoint ? 's-point-span' : 'default-tab-span'">{{totalReferPoint}}</span>
          <a [ngClass]="referalPoint ? 's-point-anchor' : 'default-tab-anchor'">{{'referralPoints'|translate}}</a>
        </div> -->
      </div>
      <!-- <div class="col-md-3 mb-4">
          <div class="d-flex flex-direction-column" [ngClass]="pollPointer ? 's-point' : 'default-tab'" (click)="getTabSelection('pollPoint')">
            <span [ngClass]="pollPointer ? 's-point-span' : 'default-tab-span'">NA</span>
            <a [ngClass]="pollPointer ? 's-point-anchor' : 'default-tab-anchor'">Poll Points</a>
          </div>
        </div> -->
    </div>

    <div class="row" id="surveyPoint" *ngIf="surveyPointer">
      <section>
        <div class="row dashboard-table table-scroll">
          <table class="table mb-4">
            <thead class="table-heading">
              <tr>
                <th>{{'surveyId'|translate}}</th>
                <th>{{'pointsEarned'|translate}}</th>
                <th>{{'date'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pagedData">
                <td>{{item.earnFromId}}</td>
                <td>{{item.points}}</td>
                <td>{{item.formattedDate}}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center text-secondary">
            {{getPointErrorMessage}}
          </div>
        </div>
      </section>
      <div class="row dashboard-table-pagination">
        
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <small>{{'rowsPerPage' | translate}}</small>
            <select  class="fs-6 mx-2 page-selection-bg" [(ngModel)]="pageSize" (change)="onPageChange()">
              <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
            </select>
            <span class="ml-2 mx-2 text-secondary fs-6">
              {{ (page - 1) * pageSize + 1 }}
              {{ getPageRange() }} of {{ surveyData.length }}
            </span>
          </div>
          
          <nav class="d-flex justify-content-end">
            <hr>
            <ngb-pagination
              class="custom-pagination"
              [collectionSize]="surveyData.length"
              [(page)]="page"
              [pageSize]="pageSize"
              [boundaryLinks]="true"
              (pageChange)="onPageChange()">
            </ngb-pagination>
          </nav>
        </div>
      </div>
    </div>

    <div class="row" id="referralPoint" *ngIf="referalPoint">
      <section>
        <div class="row dashboard-table">
          <table class="table mb-4">
            <thead class="table-heading">
              <tr>
                <th>{{'referTo'|translate}}</th>
                <th>{{'pointsEarned'|translate}}</th>
                <th>{{'date'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of referHistory">
                <td>{{item.email}}</td>
                <td>{{item.points}}</td>
                <td>{{item.formattedDate}}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center text-secondary">
            {{errorMessage}}
          </div>
          <hr />
          <!-- <div class="row dashboard-table-pagination">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div> -->
        </div>
      </section>
    </div>

    <!-- <div class="row" id="pollPoint" *ngIf="pollPointer">
      <section>
        <div class="row dashboard-table">
          <table class="table mb-4">
            <thead class="table-heading">
              <tr>
                <th>{{'referTo'|translate}}</th>
                <th>{{'pointsEarned'|translate}}</th>
                <th>{{'date'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>johnathan@gmail.com</td>
                <td>40</td>
                <td>13 days back</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div class="row dashboard-table-pagination">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </div> -->
  </div>
  <div class="page-footer"></div>
</div>