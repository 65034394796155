import { Component, DebugElement, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId } from 'src/app/base/model/constants/AppConstants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { CurrencyPipe } from '@angular/common';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-redeem-point',
  templateUrl: './redeem-point.component.html'
})
export class RedeemPointComponent implements OnInit, OnDestroy {

  constructor(
    private _baseService: BaseService,
    private _route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private customToastrService: CustomToastrServiceService,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private translate: TranslateService,
    private router:Router
  ) { }

  redeemRequestForm: FormGroup;
  Active = false;
  activeCard:boolean = false;
  userPoint;
  totalPoint;
  redeemPoint;
  availablePoints;
  availableAmount;
  conversionAmount = 0;
  panelistRedeemHistory;
  showRedeemMessage: boolean = false;
  redemptionOption;
  redemptionCardTitle;
  redemptionCardImage;
  redemptionAmount;
  minValue;
  maxValue;
  valueType;
  statusRequestMessage: string;
  variableValue;
  showValue = false;
  selectedAmountCard = false;
  Variable_Value = true;
  fixed_Value = false;
  Both_Value = false;
  selectedValue;
  redemptionItem;
  selecteditem;
  filteredObject: any;
  filteredData: any;
  currencyCode = localStorage.getItem('cS')
  redeemCurrencyCode;
  equivalentPoints;
  setPoints;
  usdAmount;
  show: boolean = false;
  isShowLoader: boolean = true;
  getUserEmail;
  passwordValue;
  userLevel;
  isShowRedeemDiv;
  rewardCatelog;
  countryBaseMessage;
  convertedOtherCurrency
  brandUtid
  isShowCatelogLoader:boolean;
  isShowCatelogMessage:boolean;
  tangoBrandCountries:string;
  emptyRedemptionCard:string;
  isEmptyRedemption:boolean;
  isRedemptionLoader:boolean;
  emptyCatelogMessage:string;
  isEmptyCatelog:boolean;
  isShowVerified:string;
  checkRedeemAmount;
  w9formSubmission;
  isShowCatelog;
  isShowFormBtn:boolean = false;
  maxRedeemValue:number;
  maxRedeemPointValue;
  minRedeemPoint;
  minimumRedeemAmount;
  exchangeRate;
  isShowError:boolean;
  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  ngOnInit() {

    // method for get user profile from sidebar value
    

    //Method for getPanelistPoint
    const getPanelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.userService.sendCountryCode.next(res?.countryCode)
      this.userService.sendUserLevel.next(res?.levelName);
      this.userService.sendUserminPoint.next(res?.minPoint);
      this.userLevel = res?.levelName;
      this.userPoint = res;
      this.totalPoint = this.userPoint.totalPoints;
      this.redeemPoint = this.userPoint.redeemPoints;
      this.availablePoints = Number(this.userPoint.availablePoints);
      this.checkRedeemAmount = Math.floor(this.redeemPoint/100);
      this.conversionAmount = res.availablePoints / 100;
      this.minRedeemPoint = res.minPoint;
      this.minimumRedeemAmount =  Math.floor(res.minAmount)
      this.currencyCode = res.currencyTo;
      this.maxRedeemPointValue = res.maxPoint / 100
      this.maxRedeemValue = Math.floor(res.maxAmount);
      this.exchangeRate = res.exchangeRate;
      this.availableAmount = Math.floor(res.availableAmount)
      this.getCatelog();
      this.ischeckFormSubmitted();
      this.userService.sendUserProfile.subscribe((userRes: any) => {
        this.getUserEmail = userRes.email
        if (userRes.isEmailVerified == true) {
          if (res.countryCode == 'US') {
            if (this.checkRedeemAmount >= 300 && !this.w9formSubmission) {
              this.isShowVerified = 'w9NotVerified'
            } else {
              this.isShowVerified = 'verified'
            }
          } else {
            this.isShowVerified = 'verified'
          }
          this.isShowLoader = false;
        } else {
          this.isShowVerified = 'notVerified';
          this.isShowLoader = false;
        } 
      })
      this.userService.sendPoints.next(res.totalPoints);
      this.userService.callUserAPI.next(true);
      if (this.currencyCode == null) {
        this.isShowError = true;
        return;
      }
    });


    // Method for create redeem request form

    this.redeemRequestForm = this.fb.group({
      getValue: new FormControl({value: '', disabled: !this.activeCard}, Validators.required)
    })


    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }
  }

  ngOnDestroy(): void {
    this.isShowFormBtn = true;
  }

  selectReward(event: any) {
    this.activeCard = event;
    const control = this.redeemRequestForm.get('getValue');
    if (!this.activeCard) {
      control.disable();
    } else {
      control.enable();
    }
    this.redemptionCardTitle = event.brandName;
    this.redemptionItem = event.items
    this.redemptionCardImage = event.imageUrls['200w-326ppi']
    this.selecteditem = event.items;
    this.valueType = this.selecteditem.valueType;
    this.minValue = this.selecteditem[0].minValue;
    this.brandUtid = event.items[0].utid
    this.catalogProduct();
    this.filteredObject = this.selecteditem.map(item => item.valueType);
    this.filteredData = this.filteredObject.filter((x, index) => {
      return this.filteredObject.indexOf(x) == index;
    });
    this.filteredData.forEach((element) => {
      if (this.filteredData.length > 1) {
        this.fixed_Value = false;
        this.Both_Value = true;
        this.Variable_Value = false;
        // console.warn('this is for both value');
      }
      else if (element === 'VARIABLE_VALUE') {
        this.Variable_Value = true;
        this.fixed_Value = false;
        this.Both_Value = false;
        // console.warn('this is variable value only');

      } else {
        this.fixed_Value = true;
        this.Both_Value = false;
        this.Variable_Value = false;
        // console.warn('this is fixed value only');
      }
    });
    this.isShowCatelogMessage = true
  }

  getCatelog(){
        const countryId = localStorage.getItem('countryId')
        this._baseService.get(userApi.getCatalog + `?panelGuid=${PanelGuId.OpinionEdge}`).then((res: any) => {
          res.brands.forEach((element, index) => {
            // if (element.brandName == 'Failure Test Brand') {
            //  res.brands.splice(index, 1)
            // }
            this.redemptionOption = res.brands;
          });
          this.tangoBrandCountries = res.countryName
        }).catch((err)=>{
          this.isEmptyRedemption = true
          this.isRedemptionLoader = false
           this.translate.get('emptyRedemptionCard').subscribe((translated)=>{
            this.emptyRedemptionCard = translated
           })
        })
  }

  validationInput(event: KeyboardEvent) {
    const inputValue = String.fromCharCode(event.keyCode);
    const pattern = /^[0-9]+$/
    if (!pattern.test(inputValue)) {
      event.preventDefault();
    }
  }

  // Method for Currency Exchange
  // getCurrencyData(val: number) {
  //   const body = {
  //     amount: val
  //   }
  //   this._baseService.post(userApi.getCurrencyExchange, body).then((res: any) => {
  //     this.availableAmount = res
  //   }).catch(err => { });
  // }

  // Method for create redeem request 
  onRedeemRequest() {
    const enteredValue = this.redeemRequestForm.value.getValue;
    if (enteredValue > this.availableAmount) {
      this.translate.get('insufficientAmount').subscribe((translate) => {
        this.toastr.error(translate)
      })
    }
    else {
      if (enteredValue > this.maxRedeemValue) {
        this.translate.get('rangeAlertWeb').subscribe((translate) => {
          this.toastr.error(translate)
        })
        const isUSD = this.selecteditem[0]?.currencyCode === 'USD';
        let levelMessage = ''
        switch (this.userLevel.toLowerCase()) {
          case 'level 1':
            levelMessage = this.translate.instant('level1LimitMessage');
            break;
          case 'level 2':
            levelMessage = this.translate.instant('level2LimitMessage');
            break;
          case 'level 3':
            levelMessage = this.translate.instant('level3LimitMessage');
            break;
          case 'level 4':
            levelMessage = this.translate.instant('level4LimitMessage');
            break;
        }
        this.countryBaseMessage = isUSD ? levelMessage + `$${this.maxRedeemPointValue}` : levelMessage  + `$${this.maxRedeemPointValue} (${this.currencyCode}  ${Math.floor(this.maxRedeemValue)})`;
      }
      else {
        const conversionRate = Number(this.exchangeRate);
        if (this.selecteditem[0]?.currencyCode === 'USD') {
          if (this.selecteditem[0].valueType === 'faceValue') {
            this.setPoints = (this.selectedValue.faceValue * 100).toFixed(0);
            this.usdAmount = Number(this.selecteditem.faceValue).toFixed(2)
          } else {
            this.setPoints = (this.redeemRequestForm.value.getValue * 100).toFixed(0)
            this.usdAmount = Number(this.redeemRequestForm.value.getValue).toFixed(2);
          }
        }
        else {
          if (this.selecteditem[0].valueType === 'faceValue') {
            this.setPoints = ((100 / conversionRate) * this.selectedValue.faceValue).toFixed(0);
            this.usdAmount = Number((1 / conversionRate) * this.selecteditem.faceValue).toFixed(2)
          } else {
            this.setPoints = ((100 / conversionRate) * this.redeemRequestForm.value.getValue).toFixed(0);
            this.usdAmount = Number((1 / conversionRate) * this.redeemRequestForm.value.getValue).toFixed(2);
          }
        }
        const body = {
          panelGuid: PanelGuId.OpinionEdge,
          amount: this.selectedValue?.faceValue || Number(this.redeemRequestForm.value.getValue),
          catalogDetail: {
            utid: this.selectedValue?.utid || this.selecteditem[0].utid,
            rewardName: this.selectedValue?.rewardName || this.selecteditem[0].rewardName,
            currencyCode: this.selecteditem[0]?.currencyCode,
            status: this.selectedValue?.status || this.selecteditem[0].status,
            valueType: this.selectedValue?.valueType || this.selecteditem[0].valueType,
            rewardType: this.selectedValue?.rewardType || this.selecteditem[0].rewardType,
            faceValue: this.selectedValue?.faceValue || Number(this.redeemRequestForm.value.getValue),
            createdDate: this.selectedValue?.createdDate || this.selecteditem[0].createdDate,
            countries: [
              this.selectedValue?.countries[0] || this.selecteditem[0]?.countries[0]
            ]
          }
        }
        let maxRedemptionLimit = 0
        let levelMessage = '';
        const userLevel = this.userLevel.toLowerCase()
        const isUSD = this.selecteditem[0]?.currencyCode === 'USD';
        const usdInputValue = this.redeemRequestForm.value.getValue;
        //  Maximum redemption limit and level-specific condition
        switch (userLevel) {
          case 'level 1':
            levelMessage = this.translate.instant('level1LimitMessage');
            break;
          case 'level 2':
            levelMessage = this.translate.instant('level2LimitMessage');
            break;
          case 'level 3':
            levelMessage = this.translate.instant('level3LimitMessage');
            break;
          case 'level 4':
            levelMessage = this.translate.instant('level4LimitMessage');
            break;
          default:
            this.createRedemptionAPI(body);
            this.redeemRequestForm.reset();
            return;
        }

        if (usdInputValue > maxRedemptionLimit) {
          this.countryBaseMessage = isUSD ? levelMessage + `$${this.maxRedeemPointValue}` : levelMessage  + `$${this.maxRedeemPointValue} (${this.currencyCode}  ${Math.floor(this.maxRedeemValue)})`;
         } else {
          this.createRedemptionAPI(body);
          this.countryBaseMessage = '';
        }
        
      }
    }
    this.redeemRequestForm.reset();
  }

  updatePanelistPoints() {
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.userLevel = res?.levelName;
      this.userPoint = res;
      this.totalPoint = this.userPoint.totalPoints;
      this.redeemPoint = this.userPoint.redeemPoints;
      this.availablePoints = Number(this.userPoint.availablePoints);
      this.checkRedeemAmount = Math.floor(this.redeemPoint/100);
      this.conversionAmount = res.availablePoints / 100;
      this.minRedeemPoint = res.minPoint;
      this.minimumRedeemAmount = res.minAmount
      this.maxRedeemPointValue = res.maxPoint / 100;
      this.currencyCode = res.currencyTo;
      // this.minRedeemValue = Math.floor(res.exchangeRate);
      this.maxRedeemValue = Math.floor(res.maxAmount);
      this.exchangeRate = res.exchangeRate;
      this.availableAmount = Math.floor(res.availableAmount)
      this.userService.sendPoints.next(res.totalPoints);
      this.userService.sendCountryCode.next(res?.countryCode);
      this.userService.sendUserLevel.next(res?.levelName);
      this.userService.sendUserminPoint.next(res?.minPoint);
    });
  }

  createRedemptionAPI(data) {
   if (data.amount !== 0) {
    if (Number.isInteger(data.amount)) {
      this._baseService.post(userApi.createRedeemRequest, data).then((res: any) => {
        this.translate.get('sentRedeemRequest').subscribe((translate) => {
          this.toastr.success(translate, '', {
            toastClass: 'success-toast',
            timeOut: 3000,
          })
        })
        this.updatePanelistPoints();
      }).catch((err) => {
        const errorMessages = err.message.toLowerCase()
        switch (errorMessages) {
          case 'not enough balance to redeem acc. to your level':
            this.translate.get('insufficientAmount').subscribe((translate) => {
              this.toastr.error(translate);
            })
            break;
          case 'we are already processing a redemption request from you':
            this.translate.get('alreadyProcessRedeemRequest').subscribe((translate) => {
              this.toastr.error(translate);
            })
            break;
          case 'only one redeem request in a day':
            this.translate.get('onlyOneRedeem').subscribe((translate) => {
              this.toastr.error(translate);
            })
            break;
          default:
            this.translate.get('somethingWentWrong').subscribe((translate) => {
              this.toastr.error(translate);
            })
        }
      })
     } else {
      this.translate.get('valueNotAllowed').subscribe((translate) => {
        this.toastr.error(translate); 
      })
     }
   } else  {
     this.translate.get('validAmount').subscribe((translate) => {
       this.toastr.error(translate);
     })
   }
  }

  onDropdownChange() {
    this.inputField.nativeElement.value = '';
  }

  redemptionOptions() {
    this.Active = false;
    if (!this.isShowError && this.currencyCode == null) this.isShowError = true;
  }

  removeMessage() {
    this.statusRequestMessage = '';
  }

  redemptionDetails() {
    this.isShowError = false;
    this.Active = true;
    const getPanelistId = localStorage.getItem('panelistId')
    this.panelistRedeemHistory = [];
    // Method for redeem history data
    this._baseService.get(userApi.getPanelistRedeemHistory).then((result: any) => {
      this.panelistRedeemHistory = result.data ? result.data : [result];
      const translateStatus = this.panelistRedeemHistory;
      translateStatus.forEach((element, index) => {
        const status = element.status.toLowerCase();
        const date = new Date(element.requestDate);
        const formattedDate = this.userService.formatDate(date);
        translateStatus[index].formattedDate = formattedDate;
        switch (status) {
          case 'pending':
            this.translate.get('pending').subscribe((translate) => {
              translateStatus[index].status = translate;
            })
            break;
          case 'approved':
            this.translate.get('approved').subscribe((translate) => {
              translateStatus[index].status = translate;
            })
            break;
          case 'disapproved':
            this.translate.get('disapproved').subscribe((translate) => {
              translateStatus[index].status = translate;
            })
            break;
        }
      })
      if (this.panelistRedeemHistory.length === 0) {
        this.showRedeemMessage = true;
      } else {
        this.showRedeemMessage = false;
      }
    }).catch((err) => {
      console.log(err.message);
      this.showRedeemMessage = true;
    })
  }

  getCurrencySymbol(currencyCode: string) {
    const currencySymbol = this.currencyPipe.transform(0, currencyCode, 'symbol');
    return currencySymbol.replace(/[0-9,.]/g, '')
  }

  cardCurencySymbol() {
    const cardCurrencyCode = this.selecteditem[0].currencyCode
    const cardCurrencySymbol = this.currencyPipe.transform(0, cardCurrencyCode, 'symbol');
    return cardCurrencySymbol.replace(/[0-9,.]/g, '')
  }

  // method for user validation and show redemption page
  showRedeemPage() {
    const body = {
      email: this.getUserEmail,
      password: this.passwordValue,
      panelGuid: PanelGuId.OpinionEdge
    }
    this._baseService.post(userApi.login, body).then((res: any) => {
      if (res) {
        this.isShowRedeemDiv = true;
      }
    }).catch(error => {
      if (error.statusCode == 'INACTIVE') {
        localStorage.clear();
        this.router.navigate(['/login'])
      } else {
        this.translate.get('incorrectPassword').subscribe((translate) => {
          this.toastr.error(translate)
        })
      }
    })
  }

  // method for send  verification email
  verifyEmail() {
    this.userService.verifyEmail(this.getUserEmail).then((res: any) => {
      this.translate.get('sentVerificationEmail').subscribe((translate) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((res) => {
      // console.log(res);
      this.toastr.error(res.error.message)
    })
  }

  // method for hide/show password
  openPassword() {
    this.show = !this.show
  }

  // show catelog 

  catalogProduct() {
    this.rewardCatelog = [];
    this.isShowCatelog = true;
    this.emptyCatelogMessage = '';
    this.isShowCatelogLoader = true
    this._baseService.get(userApi.getCatalogProduct + `?panelGuid=${PanelGuId.OpinionEdge}&productUtid=${this.brandUtid}`).then((res: any) => {
      this.rewardCatelog = res.brands
      this.isShowCatelogLoader = false;
      this.isShowCatelogMessage = false;
      this.isEmptyCatelog = false
    }).catch((err) => {
      this.isEmptyCatelog = true;
      this.isShowCatelogLoader = false;
      this.translate.get('emptyCatelog').subscribe((res)=>{
        this.emptyCatelogMessage = res
      })
     })
  }

  redirectW9Tab(){
    this.userService.selectedTab = 'w9Form';  // Set default tab to 'Fill W9 Form'
    this.router.navigate(['/login/body/personal-detail']);
  }

  ischeckFormSubmitted(){
    this._baseService.get(userApi.checkFormSubmitted).then((res:any) => {
      this.w9formSubmission = res.isFormSubmitted;
    }).catch((err) => {
      this.w9formSubmission = err.isFormSubmitted;
    })
  }

  getRedeemInfoText():string{
    const amount = this.minRedeemPoint - this.availablePoints;
    const minimumPoints = this.translate.instant('pointRemaining')
    return `${amount} ${minimumPoints}`;
  }
}
