import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { UserService } from '../../services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-survey',
  templateUrl: './my-survey.component.html'
})
export class MySurveyComponent implements OnInit {
  constructor(private _baseService: BaseService,
    private toastr: ToastrService,
    private router: Router,
    private customToastrService: CustomToastrServiceService,
    private userService: UserService,
    private translate:TranslateService,
    private route: ActivatedRoute,
    private deviceDetector: DeviceDetectorService,) {
    this.epicFunction()
    this.debouncedGetSurvey = this.userService.debounce(this.getSurvey.bind(this), 10);
  }
  debouncedGetSurvey: any;
  isEmailVerified:boolean =false;
  surveyData;
  noSurveyForUser;
  pointBalance;
  showDataMessage = false;
  getIpAddress;
  isDisabled: boolean = false;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  surveyDevice;
  val: string;
  surveyDeviceEligible;
  access_token = localStorage.getItem('access_token')
  isShowAToast:boolean = false;
  isToastPops:boolean = false;
  isLoader:boolean;
  userEmail;
  userCountryCode:string;
  currentCountryCode:string;
  isVPN:boolean;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.access_token
  });
  miniOffer:boolean = false;;
  myOffer:boolean = true;
  isEmailCheck:boolean;
  isSecurityCheck:any;
  ngOnInit() {
    this.isLoader = true;
    this.userService.sendUserProfile.subscribe((res:any)=>{
      this.userEmail = res.email
      this.isEmailVerified = res.isEmailVerified;
      setTimeout(() => {
        this.isEmailCheck = true;
      }, 0);
    })
    if (this.deviceDetector.os.toLowerCase() !== 'windows' && localStorage.getItem('isShowPops')) {
      this.isToastPops = true;
      this.isShowAToast = true;
    };
    // Method for surveyForUser

    // Method for get iP address
    this._baseService.get(userApi.ipAddress).then((res: any) => {
      this.getIpAddress = res.IP
    })
    this.getUserSecurityCheck();
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }
    console.error = function () { }
  }

  getUserSecurityCheck(){
    this.userService.getSurveyCheckAPI().then((res:any)=>{
      const userCurrentDetails = res
      this.currentCountryCode = userCurrentDetails?.param1;
      this.isSecurityCheck = userCurrentDetails?.param4.toLowerCase() === 'true';
      this.isVPN = userCurrentDetails?.param2;
      this.getTotalPoint();
    }).catch((err)=>{
      this.isLoader = false;
      this.showDataMessage = true;
      this.getTotalPoint();
    })
  }

  selectButton(button:string){
    this.miniOffer = button === 'miniOffer';
    this.myOffer = button === 'myOffer';
    this.surveyData = [];
    this.onShowSurveyWithSecurityCheck(button);
  }

  onShowSurveyWithSecurityCheck(button:string) {
    if (this.isSecurityCheck) {
      if (this.currentCountryCode === this.userCountryCode  && !this.isVPN ) {
        if (button == 'myOffer') {
          this.showDataMessage = false;
          this.getSurveyForUser('1');
        } else if (button == 'miniOffer') {
          this.getSurveyForUser('2');
        }
      } else {
        this.isLoader = false;
        this.showDataMessage = true;
      }
    } else {
      if (button == 'myOffer') {
        this.showDataMessage = false;
        this.getSurveyForUser('1');
      } else if (button == 'miniOffer') {
        this.getSurveyForUser('2');
      }
    }
  }

  getSurveyForUser(surveyFor){
    this.isLoader = true;
    this._baseService.get(`${userApi.getSurveyForUser}?surveyOption=${surveyFor}`).then((result: any) => {
      this.isLoader = false;
      if (this.miniOffer && surveyFor === '2' || this.myOffer && surveyFor === '1') {
        this.surveyData = result.data ? result.data : [result];
        this.surveyData.forEach(element => {
          element.isButtonClicked = false;
          this.checkDeviceType(element)
        });
        this.showDataMessage = this.surveyData.length === 0;
      }
    }).catch((error: any) => {
      if (this.miniOffer && surveyFor === '2' || this.myOffer && surveyFor === '1') {
        this.isLoader = false;
        this.showDataMessage = true;
      }
      // this.isLoader = false;
      // this.showDataMessage = true;
    })
  }
  // Method for totalPoints
  getTotalPoint(){
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.pointBalance = res.availablePoints;
      this.userCountryCode = res?.countryCode;
      this.selectButton('myOffer');
      this.userService.sendPoints.next(res.totalPoints);
      this.userService.sendCountryCode.next(res?.countryCode)
      this.userService.sendUserLevel.next(res?.levelName);
      this.userService.sendUserminPoint.next(res?.minPoint);
      this.userService.callUserAPI.next(true)
    })
  }
  // Method for take survey
  getSurvey(surveyId: number, clientUrl: any, item:any) {
    if (item.isButtonClicked || this.isDisabled) {
      return;
    }
    item.isButtonClicked = true;
    this.isDisabled = true;
    const panelistId = localStorage.getItem('panelistId');
    const surveyAlreadyExists = this.translate.instant('alreadyParticipateSurvey')
    const arr = this.getIpAddress
    const body = {
      panelistId: Number(panelistId),
      surveyId: surveyId,
      ipAddress: arr,
      surveyFrom: 3
    }
    this._baseService.post(userApi.creatUserSurveyParticipation, body).then((res: any) => {
      localStorage.setItem('my_token', res.token)
      if (res.userStatus === 1) {
        this._baseService.get(userApi.getSurveyLinkWithQualification + `?panelistId=${panelistId}&surveyId=${surveyId}`).then((resLink: any) => {
          const getToken = localStorage.getItem('my_token')
          if (resLink.includes('{tid}')) {
            const redirectUrl = resLink.replace('{tid}', getToken).replace('{pid}', panelistId)
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              if (updateRes.userStatus === 8) {
                window.location.href = redirectUrl;
                return;
              }
              if (updateRes.userStatus === 7) {
                this.router.navigate([`/callback-survey/${7}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 10) {
                this.router.navigate([`/callback-survey/${10}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 17) {
                this.router.navigate([`/callback-survey/${17}/${getToken}`])
                return;
              }
              this.router.navigate([`/callback-survey/${updateRes.userStatus}/${getToken}`])
            })
          } else {
            this.toastr.error('Invalid client Url')
            const getToken = localStorage.getItem('my_token')
            this.router.navigate([`/callback-survey/${11}/${getToken}`])
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              console.log('Invalid client Url', updateRes);
            })
          }
        })
      }else{
        this.isDisabled = false;
        item.isButtonClicked = false;
        const getToken = localStorage.getItem('my_token')
        this.router.navigate([`/callback-survey/${res.userStatus}/${getToken}`])
      }
    }).catch((err)=>{
      this.isDisabled = false;
      item.isButtonClicked = false;
      this.router.navigate([`/callback-survey/${err.userStatus}/${err.token}`])
    })
  }

  epicFunction() {
    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();
    this.isDesktop = this.deviceDetector.isDesktop();
  }

  checkDeviceType(item): any {
    const isUserDesktop = this.isDesktop;
    const isUserMobile = this.isMobile;
    const isUserTablet = this.isTablet;
    item.isDisabled = true;
    switch (true) {
      case item.isDesktop && isUserDesktop:
      case item.isMobile && isUserMobile:
      case item.isIpad && isUserTablet:
      case (item.isDesktop && item.isIpad && item.isMobile) &&
           (isUserDesktop || isUserTablet || isUserMobile):
        item.isDisabled = false;
        break;
      default:
        item.isDisabled = true;
        break;
    }

    

  }


  getTooltipText(item: any): string {
    const surveyDeviceArray = [];
  
    if (item.isDesktop) {
      surveyDeviceArray.push(this.translate.instant('desktop'));
    }
    if (item.isMobile) {
      surveyDeviceArray.push(this.translate.instant('mobile'));
    }
    if (item.isIpad) {
      surveyDeviceArray.push(this.translate.instant('tablet'));
    }
  
    switch (surveyDeviceArray.length) {
      case 1:
      case 2:
        const devices = surveyDeviceArray.join(' ' + this.translate.instant('and') + ' ');
        const showMessage = this.translate.instant('availableOn') + ' ' + devices
        return showMessage;
      case 3:
        const allDeviceMes = this.translate.instant('availableOn') + ' ' + this.translate.instant('allDevices')
        return allDeviceMes;
      default:
        return this.translate.instant('noSupportedDevice');
    }
  }

  downloadApp(){
    const device = this.deviceDetector.os.toLowerCase();
    if (device === 'ios') {
     window.location.href = 'https://apps.apple.com/us/app/opinion-edge/id6446476343'
    } else if (device === 'android'){
      window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.unipanel'
    }
  }

  closeToast(){
    localStorage.removeItem('isShowPops')
  }

  sendEmailButton(){
    this.userService.verifyEmail(this.userEmail).then((res: any) => {
      this.translate.get('sentVerificationEmail').subscribe((translate) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((res) => {
      // console.log(res);
      this.toastr.error(res.error.message)
    })
  }
}
