import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, finalize, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/modules/users/services/token.service';
import { environment } from 'src/environments/environment';
import { userApi } from '../model/constants/ApiEndPoints';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private tokenService:TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes(environment.baseUrl+userApi.login)) {
      return next.handle(req).pipe(
        catchError((error: any) => {
          console.log('hit loginAPI');
          return throwError(error);
        })
      );
    }
    
    const token = this.tokenService.getAccessToken();
    if (token) {
      req = this.addToken(req, token);
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          const baseUrl = new URL(error.url).origin + new URL(error.url).pathname;
          if (baseUrl == environment?.baseUrl+userApi?.surveyLanding) {
            return throwError(error);
          }
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      localStorage.setItem('access_token',this.tokenService.getRefreshToken())
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.tokenService.refreshToken().pipe(
        switchMap((newToken: any) => {
          this.isRefreshing = false;
          localStorage.setItem('access_token', newToken.data.access.token) // set new access token
          localStorage.setItem('refresh_token', newToken.data.refresh.token ) // set new access token
          this.refreshTokenSubject.next(newToken.data.access.token);
         return next.handle(this.addToken(req, newToken.data.access.token)).pipe(
          retry(1),
         )
         
        }),
        catchError((error: any) => {
            this.isRefreshing = false; 
            localStorage.clear();
            this.router.navigate(['/login']);
            return throwError(error)
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => {
          if (req.url.includes(environment.baseUrl+userApi.refreshtokenauth)) {
            return true;
          }
          return token !== null
        }),
        take(1),
        switchMap(() => {
          return next.handle(this.addToken(req, this.tokenService.getAccessToken()))
        })
      );
    }
  }
}
