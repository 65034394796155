export const PointTypeId = {
    getForSurvey: '2'
}

export const PanelGuId = {
    OpinionEdge: '75a22a9e-d0e4-4547-af6b-6156bb0760eb'
}

export const PanelId ={
    OpinionEdge:'30'
}

export const RecaptchaSiteKey = {
    siteKey: '6Lc342spAAAAANWDdYqidx1fY5CFo0KbiJqfpjdY'
}


export const SupportReson = {
    appAndroidIos: "App-Android/IOS",
    complaint: "Complaint",
    generalQuestion: "General Question",
    loginIssue: "Login Issue",
    support: "Support",
    surveyMissingPoints: "Survey Missing Points",
    technical: "Technical",
    noSurvey: "Survey Not Available",
    redemption: "Redemption"
}