import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from 'src/app/modules/users/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  constructor(private _baseService: BaseService, private router: Router, private translate: TranslateService, private userService:UserService) { }

  getNotification;
  notificationCount = 0;
  showCount = false;
  isShowMessage = false;
  isNotificationRead = false;
  userAPISubscription:Subscription;
  ngOnInit() {
    const getPanelistId = localStorage.getItem('panelistId')
    this.userAPISubscription = this.userService.callUserAPI.subscribe((res)=>{
      if (res) {
        this._baseService.get(userApi.notificationByPanelistId).then((res: any) => {
          this.showCount = true;
          this.getNotification = res.data ? res.data : [res];
          this.getNotification.forEach((element, index) => {
            this.isNotificationRead = element?.isRead;
            const notificationCategory = element.notificationType.toLowerCase();
            const date = new Date(element.createdAt);
            const formattedDate = this.userService.formatDate(date);
            this.getNotification[index].formattedDate = formattedDate;
            this.translate.get(notificationCategory).subscribe((translate: any) => {
              this.getNotification[index].notificationType = translate;
            })
            switch (notificationCategory) {

              case 'email verified':
                this.translate.get('emailVerifiedMessage').subscribe((translate: any) => {
                  this.getNotification[index].notification = translate;
                })
                break;
              case 'survey':
                this.translate.get('surveyAlert').subscribe((translate: any) => {
                  this.getNotification[index].notification = translate;
                })
                break;
              case 'verify email':
                this.translate.get('verifyEmail').subscribe((translate: any) => {
                  this.getNotification[index].notification = translate;
                })
                break;
              case 'signup':
                this.translate.get('signupMessage').subscribe((translate: any) => {
                  this.getNotification[index].notification = translate;
                })
                break;
              case 'survey complete':
                this.translate.get('surveyComplete').subscribe((translate: any) => {
                  this.getNotification[index].notification = translate;
                })
                break;
            }
          });
          const readCount = res.data.filter(obj => obj.isRead == false).length;
          if (readCount == 0) {
            this.showCount = false;
          }
          this.notificationCount = readCount;
        }).catch((error: any) => {
          this.showCount = false;
          this.isShowMessage = true;
          // if (error.message === 'invalid token') {
          //   localStorage.clear();
          //   this.router.navigate(['login']);
          // }
        })
      }
    })
  }

  isLogout() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function(registration) {
        // Unregister the service worker
        registration.unregister().then(function(success) {
          if (success) {
            console.log('Service worker unregistered successfully');
          } else {
            console.log('Service worker unregister failed');
          }
        }).catch(function(error) {
          console.log('Error during service worker unregister:', error);
        });
      }).catch(function(error) {
        console.log('Service worker registration not ready:', error);
      });
    }
    const valueToKeep = localStorage.getItem('Uculture');
    localStorage.clear();
    if (valueToKeep !== null) {
      localStorage.setItem('Uculture', valueToKeep);
    }
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }

  onShowNotification(item, notificationId) {
    if (!item.isRead) {
      const isRead = item.isRead = true;
      const body = {
        notificationType: notificationId,
        isRead: isRead
      }
      this._baseService.patch(userApi.readNotification + `?notificationId=${notificationId}`, body).then((res: any) => {
        this.isNotificationRead = true;
        if (res?.isRead) {
          this.notificationCount = this.notificationCount - 1;
          if (this.notificationCount == 0) {
            this.showCount = false;
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.userAPISubscription.unsubscribe();
  }
}
