
<header class="sticky-top">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
      </a>
    </div>
    <hr />
  </nav>
</header>
<div class="container center-div mx-width-700">
    <div class="loader" *ngIf="isShowLoader" >
        <span></span>
        <span></span>
        <span></span>
    </div>

    <div class="unverified-email" *ngIf="isShowVerified">
        <div class="text-center fs-2 text-success">
            <i class="fa-sharp fa-solid fa-circle-check"></i>
        </div>
        <div class="mt-4">{{'verifySuccessMessage'|translate}}</div>
        <!-- <div class="mt-3 text-center fw-bold" *ngIf="showMobileDevice">{{'please'|translate}} <a class="text-primary redirectlogin" routerLink="/">{{'clickHere'|translate}}</a> {{'toLogIn'|translate}}</div>          -->
        <div class="mt-3 text-center fw-bold" *ngIf="showMobileMessage">
          <!-- <small class="mx-2">Continue</small>
          <small class="text-primary cursor-pointer" (click)="continueBrowser()">with browser</small> 
          <small class="mx-2">or</small> 
          <small class="text-primary cursor-pointer" (click)="openWithApp()">open with app</small> -->
          <h5>{{'pleaseGoTo'|translate}}</h5>
        </div>
        <div class="loader mt-4" *ngIf="isRedirectLoader">
          <span></span>
          <span></span>
          <span></span>
      </div>         
    </div>
    <div class="unverified-email" *ngIf="isShowUnVerified">
      <div class="text-center text-primary mb-2">
        <i class="fa-sharp fa-solid fa-triangle-exclamation fs-1"></i>
      </div>
        <div class="mt-4 text-center">{{'verificationFailed'|translate}}</div>
        <div class="message-bottum">
          {{'linkExpire'|translate}} <br>
          {{'thankYou'|translate}} 
        </div>
    </div>
<!-- 
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fs-5" id="exampleModalLabel">Please continue with your choice</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <button type="button" class="btn btn-secondary btn-sm" (click)="continueBrowser()">continue with browser</button>
                <button type="button" class="btn btn-primary btn-sm mx-3" (click)="openWithApp()">Open with app</button>
            </div>
          </div>
        </div>
      </div> -->
</div>