<div class="container-fluid">
  <div class="row fixed-topheader dashboard">
      <div class="row dashboard-data sticky-top">
        <div class="d-flex justify-content-between dashboard-mainboady">
          <div class="dashboard-heading">
            <h3>{{'dashboard'|translate}}</h3>
          </div>
          <div class="dashboard-headingicons">
            <app-header></app-header>
          </div>
        </div>
        <hr class="dashboardhr-line" />
      </div>
  </div>
   <div class="row fixed-body">
    <div class="row dashboard-cards p-0">
      <div class="col-md-3">
        <div [ngClass]="!showPoints?'card':'card card-active'" (click)="getpopUp()">
          <h3 class="card-text">{{totalPoint}}</h3>
          <small>{{'totalPoints'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showPoints">
          <div routerLink="/login/body/redeem-point"> {{'redeemPoints'|translate}}</div>
          <!-- <div>Show Survey History</div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div [ngClass]="!showCompleteSurvey?'card':'card card-active'" (click)="getCompleteSurvey()">
          <h3 class="card-text">{{completeSurvey}}</h3>
          <small>{{'surveysCompleted'|translate}}</small>
        </div>
        <!-- <div class="row showpop" *ngIf="showCompleteSurvey">
          <div>{{'showSurveyHistory'|translate}}</div>
        </div> -->
      </div>
      <div class="col-md-3">
        <div [ngClass]="!showSentReferrals?'card':'card card-active'" (click)="getSentReferrals()">
          <h3 class="card-text">{{sentReferral}}</h3>
          <small>{{'referralsSent'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showSentReferrals">
          <div routerLink="/login/body/refer-earn">{{'referEarn'|translate}}</div>
          <!-- <div>Show Survey History</div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div [ngClass]="!showAvailablePoint?'card':'card card-active'" (click)="getAvailablePoint()">
          <h3 class="card-text">{{availablePoints}}</h3>
          <small>{{'balancePoints'|translate}}</small>
        </div>
        <div class="row showpop" *ngIf="showAvailablePoint">
          <div routerLink="/login/body/redeem-point">{{'redeemPoints'|translate}}</div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="dashboardbody">
        <h3>{{'topPerformingSurvey'|translate}}</h3>
      </div>
    </div>

    <section>
      <div class="row dashboard-table table-responsive">
        <table [ngClass]="isEmailVerified ? 'table-hover':''" class="table table-sm mb-4">
          <thead class="table-heading white-space-nowrap">
            <tr class="tr-bottom-primary th-w-25">
              <th>{{'surveyId'|translate}}</th>
              <th>{{'time'|translate}}</th>
              <th>{{'reward'|translate}}</th>
              <th>{{'availableOn'|translate}}</th>
            </tr>
          </thead>
          <tbody *ngIf="!isLoader">
            <ng-container *ngIf="isEmailCheck">
              <ng-container *ngIf="isEmailVerified; else notVerified">
                <tr *ngFor="let item of surveyData">
                  <td>{{item.surveyCode}}</td>
                  <td>{{item.LOI}}</td>
                  <td>{{item.completedPoints}}</td>
                  <td>
                    <div class="text-primary">
                      <span [ngClass]="item.isMobile ?'':'show-mobile'"><i class="fa-solid fa-mobile-screen-button"></i></span>
                      <span [ngClass]="item.isIpad ?'mx-3':'show-mobile mx-3'"><i class="fa-solid fa-tablet-screen-button"></i></span>
                      <span [ngClass]="item.isDesktop?'':'show-mobile'"><i class="fa-solid fa-desktop"></i></span>
                    </div>
                  </td>
                  <td [ngClass]="item.isDisabled || isDisabled ? 'disabled' : ''" placement="top" [ngbTooltip]="getTooltipText(item)" *ngIf="!item.isButtonClicked">
                    <a (click)="debouncedGetSurvey(item.surveyId,item.clientUrl, item)" class="btn btn-primary table-btn">{{'start'|translate}}</a>
                  </td>
                  <td *ngIf="item.isButtonClicked" class="text-center d-flex justify-content-center">
                    <button class="btn btn-primary dash-card-button" disabled>
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="showDataMessage">
                  <td colspan="5" class="text-center text-secondary">
                    {{'emptySurveyMessage' | translate}}
                  </td>
                </tr>
              </ng-container>
              <ng-template #notVerified>
                <tr>
                  <td colspan="5">
                    <div class="page-container">
                      <div class="blurred" [class.visible]="!isEmailVerified">
                        <table class="table table-sm mb-4">
                          <tbody>
                            <tr *ngFor="let item of surveyData">
                              <td>{{item.surveyCode}}</td>
                              <td>{{item.LOI}}</td>
                              <td>{{item.completedPoints}}</td>
                              <td>
                                <div class="text-primary">
                                  <span [ngClass]="item.isMobile ? '' : 'show-mobile'">
                                    <i class="fa-solid fa-mobile-screen-button"></i>
                                  </span>
                                  <span [ngClass]="item.isIpad ? 'mx-3' : 'show-mobile mx-3'">
                                    <i class="fa-solid fa-tablet-screen-button"></i>
                                  </span>
                                  <span [ngClass]="item.isDesktop ? '' : 'show-mobile'">
                                    <i class="fa-solid fa-desktop"></i>
                                  </span>
                                </div>
                              </td>
                              <td >
                                <a class="btn btn-primary btn-sm btn-survey">{{'start' | translate}}</a>
                              </td>
                            </tr>
                            <tr class="text-center text-secondary" *ngIf="showDataMessage">
                              {{'emptySurveyMessage'|translate}}
                             </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="overlay" [class.visible]="!isEmailVerified">
                        <div class="verification-div d-flex justify-content-center mb-2">
                          <div class="text-center overlay-bg m-3 fs-6">
                            <div class="verifymessage-box fs-6">
                              {{'surveyEmailVerifyMessage'|translate}}
                            </div>
                            <strong>{{'notReceiveEmail' | translate}}</strong>
                            <div><span class="btn btn-primary btn-sm cursor-pointer" (click)="sendEmailButton()">{{'resend'|translate}}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
        <span *ngIf="surveyData?.length >= 10 && isEmailVerified;" class="viewMoreSurveyBtn" routerLink="/login/body/my-survey">{{'viewMore'|translate}}</span>
        <div class="d-flex justify-content-center mt-2" *ngIf="isLoader">
          <div class="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <hr />
        <div class="row dashboard-table-pagination">
        </div>
        <div class="row mt-3 mb-3">
          <!-- <span class="show-all-survey">
            <a href="#">Show all Surveys</a>
          </span> -->
        </div>
      </div>
    </section>

    <section>
      <div class="row">
        <div class="dashboardbody">
          <h3>{{'recentReferals'|translate}}</h3>
        </div>
      </div>
      <div class="row dashboard-table">
        <table class="table mb-4">
          <thead class="table-heading">
            <tr  class="tr-bottom-primary th-w-25">
              <th>{{'name'|translate}}</th>
              <th>{{'emailId'|translate}}</th>
              <!-- <th>{{'referDate'|translate}}</th> -->
              <th>{{'status'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of referHistory">
              <td>{{item.firstName}}&nbsp; {{item.lastName}}</td>
              <td>{{item.email}}</td>
              <td>{{ item.status == 2 ? ('joined'|translate) : ('pending'|translate)}}</td>
            </tr>
          </tbody>
        </table>
        <div class="text-center text-secondary">
          {{emptyMessage}}
        </div>
        <hr />
        <div class="row dashboard-table-pagination">

        </div>
        <div class="row mt-3 mb-3">
          <!-- <span class="show-all-survey">
            <a href="#">Refer & Earn </a>
          </span> -->
        </div>
      </div>
    </section>

    <router-outlet></router-outlet>
   </div>
   <div class="page-footer"></div>
</div>

<router-outlet></router-outlet>