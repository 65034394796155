<header class="sticky-top">
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
        </a>
      </div>
      <hr />
    </nav>
</header>

<div class="container-fluid" *ngIf="!isShowErrorMessage">
   <div class="d-flex justify-content-center mt-5">
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
   </div>
</div>
<div class="container-fluid" *ngIf="isShowErrorMessage">
  <div class="container center-div mx-width-700">
    <div class="unverified-email">
      <img class="rd-level-img mb-2" src="../../../../../assets/image/oops.png" alt="image">
      <div>{{isShowErrorMessage}}</div>
      <div class="mt-4 text-center">
        <div class="text-center mx-2">
          <p>{{'pleaseWait'|translate}}</p>
        </div>
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</div>
