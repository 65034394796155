import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service'
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';

@Component({
  selector: 'app-survey-landing',
  templateUrl: './survey-landing.component.html'
})
export class SurveyLandingComponent implements OnInit {

  constructor(
    private ActivateRoute: ActivatedRoute,
    private router: Router,
    private _baseService: BaseService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) { }
    isShowErrorMessage:string;
    errorMessage;
  ngOnInit() {
    // Method for getting query parameters data 
    this.ActivateRoute.queryParams.subscribe((params) => {
      const queryParamsKay = params['token'];
      if (queryParamsKay) {
        this.cookieService.set('userSKey', queryParamsKay);
        this._baseService.get(userApi.ipAddress).then((res: any) => {
          const getIpAddress = res.IP;
          this.userSurvey(queryParamsKay, getIpAddress);
        }).catch((err: any) => { })
      } else {
        console.error('Token not found in query parameters.');
      }
    })

  }

  // Method for getting the survey id and user panelistId
  userSurvey(queryParamsKay, getIpAddress) {
    
    const paramsToken =  encodeURIComponent(queryParamsKay) || queryParamsKay
    console.log(paramsToken);
    this._baseService.get(userApi.surveyLanding + `?token=${paramsToken}`).then((res: any) => {
      
      const userPanelistId = res.data[0].panelistId;
      const userSurveyId = res.data[0].surveyId;
      const batchId = res.data[0].batchId;
      localStorage.setItem('access_token', res.token.access.token)
      localStorage.setItem('refresh_token', res.token.refresh.token)
      localStorage.setItem('countryId', res.data[0].countryId)
      localStorage.setItem('languageId', res.data[0].languageId)
      localStorage.setItem('panelistId', userPanelistId)
      localStorage.setItem('url_refer', res.data[0].referralCode)
      localStorage.setItem('profileImage', res.data[0].panelistImage),
      localStorage.setItem('cS',res.data[0].currency)
      sessionStorage.setItem('iEV', res.data[0]?.isEmailVerified);
      this.surveyClick(batchId,userSurveyId,userPanelistId)
      this.userAuthSurvey(userPanelistId, userSurveyId, getIpAddress);
      // if (loggedInPanelistId) {
      //   if (loggedInPanelistId == userPanelistId) {
      //     this.cookieService.delete('userSKey')
      //     this.userAuthSurvey(userPanelistId, userSurveyId, getIpAddress);
      //   }
      //   else {
      //     // Deffrent user logged in
      //     localStorage.clear();
      //     this.router.navigate(['/login'])
      //   }
      // } else {
      //   // No user logged in
      //   this.router.navigate(['/login'])
      // }
    }).catch((err) => {
      this.cookieService.delete('userSKey')
      this.isShowErrorMessage = this.translateService.instant('emailLinkExpire');
      setTimeout(() => {
        localStorage.clear()
        this.router.navigate(['/login']);
      }, 3000);
    })
  }

  // Method for take user Survey
  userAuthSurvey(panelistId, surveyId, getIpAddress) {
    localStorage.removeItem('bulk_token')
    this.cookieService.delete('userSKey')
    const body = {
      panelistId: Number(panelistId),
      surveyId: surveyId,
      ipAddress: getIpAddress,
      surveyFrom: 4
    }
    const surveyAlreadyExists = this.translateService.instant('alreadyParticipateSurvey')
    this._baseService.post(userApi.creatUserSurveyParticipation, body).then((res: any) => {
      
      localStorage.setItem('my_token', res.token)
      if (res.userStatus === 1) {
        this._baseService.get(userApi.getSurveyLinkWithQualification + `?panelistId=${panelistId}&surveyId=${surveyId}`).then((resLink: any) => {
          const getToken = localStorage.getItem('my_token')
          if (resLink.includes('{tid}')) {
            const redirectUrl = resLink.replace('{tid}', getToken).replace('{pid}', panelistId)
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              if (updateRes.userStatus === 8) {
                window.location.href = redirectUrl;
                return;
              }
              if (updateRes.userStatus === 7) {
                this.router.navigate([`/callback-survey/${7}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 10) {
                this.router.navigate([`/callback-survey/${10}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 17) {
                this.router.navigate([`/callback-survey/${17}/${getToken}`])
                return;
              }
              this.router.navigate([`/callback-survey/${updateRes.userStatus}/${getToken}`])
            })
          } else {
            this.toastr.error('Invalid client Url')
            const getToken = localStorage.getItem('my_token')
            this.router.navigate([`/callback-survey/${11}/${getToken}`])
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              console.log('Invalid client Url', updateRes);
            })
          }
        })
      } else {
        // if (!res.userStatus) {
        //   this.isShowErrorMessage = surveyAlreadyExists;
        //   return;
        // }
        const getToken = localStorage.getItem('my_token')
        this.router.navigate([`/callback-survey/${res.userStatus}/${getToken}`])
      }
    }).catch((err) => {
      // if (!err.userStatus) {
      //   this.isShowErrorMessage = surveyAlreadyExists;
      //   return;
      // }
      this.router.navigate([`/callback-survey/${err.userStatus}/${err.token}`])
    })
  }


  surveyClick(batchId,surveyId,panelistId){
    
    this._baseService.get(userApi.surveyClick + `?batchId=${batchId}&surveyId=${surveyId}&panelistId=${panelistId}`).then((response)=>{
       
    })
  }
}
